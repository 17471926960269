import React from 'react'

import Layout from '../components/Layout'

import Hero from '../components/home/Hero';
import Subhero from '../components/home/Subhero';
import Features from '../components/home/Features';
import Prefooter from '../components/Prefooter';


export const IndexPageTemplate = () => (
    <div>
        <Hero/>
        <Subhero/>
        <Features/>
        <Prefooter/>
    </div>
)

const IndexPage = () => {
    return (
        <Layout>
            <IndexPageTemplate/>
        </Layout>
    )
}

export default IndexPage;