import React from "react";
import {Link} from "gatsby";

import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import herobackground from '../../img/home/dancers-no-background.png';

const useStyles = makeStyles(theme => ({
    fullContainer: {
        backgroundColor: "#f1f1f1",
        borderTopColor: "#868686",
        borderTopStyle: "solid",
        borderTopWidth: "1px",
    },
    heroContainer: {
        minHeight: '50vh',
        borderBottomColor: "#868686",
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
    },
    heroImageDiv: {
        background: `url(${herobackground})`,
        backgroundRepeat: "no-repeat",
        backgroundOrigin: "content-box",
        height: "50vh",
        padding: "1rem",        
        backgroundPosition: "top right",
    },
    heroTextDiv: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "1rem",
        //marginTop: "6rem",
    },
    heroTitle: {
        fontSize: "46px",
        color: "#262626",
        lineHeight: "1.1",
        marginBottom: "20px",
    },
    heroSubtitle: {
        fontSize: "20px",
        color: "#262626",
        lineHeight: "1.1",
        marginBottom: "2rem",
    },
    heroButton: {
        fontSize: "24px",
        fontWeight: "500",
        lineHeight: "2",
        marginBottom: "10px",
    },
}));

const Hero = () => {
    
    const classes = useStyles();

    return(
        <Container maxWidth={false} className={classes.fullContainer}>
            <Container className={classes.heroContainer}>
                <Grid container>
                    <Grid item container xs={12} md={6} className={classes.heroTextDiv}>
                        <Typography variant="h2" component="p" align='center' className={classes.heroTitle}>
                            A platform for dancers 
                        </Typography>
                        <Typography variant="h2" component="p" align='left' className={classes.heroSubtitle}>
                            Our software brings studios and students together on the same great platform. 
                        </Typography>
                        <div style={{width: "100%", textAlign: "center"}}>
                            <Button variant="contained" color="secondary" component={Link} to="/register" className={classes.heroButton}>Register</Button>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.heroImageDiv}>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    )
}

export default Hero;