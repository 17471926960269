import React from "react";
import {Link} from "gatsby";

import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles(theme => ({
    wideContainer: {
        padding: "1rem",
        paddingBottom: "1rem",
        //backgroundColor: "#3c96d2",
        backgroundColor: "#f1f1f1",
    },
    button: {
        fontSize: "18px",
        fontWeight: "500",
        lineHeight: "2",
        marginBottom: "1rem",
        margintop: "1rem"
    },
}));

const Footer = () => {
    
    const classes = useStyles();

    return(
        <Container maxWidth={false} className={classes.wideContainer}>
            <Container>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h5" component="h3" align="center" style={{color: "#262626"}}>Dance with Clarity</Typography>
                        <div style={{width: "100%", textAlign: "center", paddingTop: "2rem", paddingBottom: "2rem"}}>
                            <Button variant="contained" color="secondary" component={Link} to="/register" className={classes.button}>Register for Early Access</Button>    
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    )
}

export default Footer;