import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import TrendingUp from "@material-ui/icons/TrendingUp";
import Forum from "@material-ui/icons/Forum";
import Assessment from "@material-ui/icons/Assessment";
import Schedule from "@material-ui/icons/Schedule";

const useStyles = makeStyles(theme => ({
    fullContainer: {
        //backgroundColor: "#739af0",
        backgroundColor: "#f1f1f1",
    },
    item: {
        padding: "1rem",
        margin: "1rem",
    },
    itemTextDiv: {
        overflow: "hidden", 
        padding: '1rem',
    },
    itemRow: {

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "2rem",
        paddingBottom: "2rem",
    },
    icon: {
        fontSize: "48px",
    },
    itemText: {
        fontSize: "24px",
        color: "#000",
        lineHeight: "1.1",
        marginBottom: "20px",
    },
}));

const Hero = () => {
    
    const classes = useStyles();

    return(
        <Container maxWidth={false} className={classes.fullContainer}>
            <Container>
                <Grid container>
                    <Grid container item xs={12} md={6} lg={3} className={classes.itemRow}>
                        <Card>
                            <CardContent>
                                
                                <Grid container direction="column" justify="center" alignItems="center">
                                    <TrendingUp 
                                            color="secondary"
                                            style={{display: "flex", alignContent: "center", justifyContent: "center"}}
                                            className={classes.icon}></TrendingUp>
                                    <Typography variant="h2" component="span" className={classes.itemText}>
                                            Improved Productivity
                                    </Typography>
                                </Grid>

                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid container item xs={12} md={6} lg={3} className={classes.itemRow}>
                        <Card>
                            <CardContent>
                                <Grid container direction="column" justify="center" alignItems="center">
                                    <Forum color="secondary" className={classes.icon}></Forum>
                                    <Typography variant="h2" component="span" align='left' className={classes.itemText}>
                                        Better Communication
                                    </Typography>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid container item xs={12} md={6} lg={3} className={classes.itemRow}>
                        <Card>
                            <CardContent>
                                <Grid container direction="column" justify="center" alignItems="center">
                                    <Assessment color="secondary" className={classes.icon}></Assessment>
                                    <Typography variant="h2" component="span" align='left' className={classes.itemText}>
                                        Track Student Progress
                                    </Typography>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid container item xs={12} md={6} lg={3} className={classes.itemRow}>
                        <Card>
                            <CardContent>
                                
                                <Grid container direction="column" justify="center" alignItems="center">
                                    <Schedule color="secondary" className={classes.icon}></Schedule>
                                    <Typography variant="h2" component="span" align='left' className={classes.itemText}>
                                        Allow Online Scheduling
                                    </Typography>
                                </Grid>

                            </CardContent>
                        </Card>
                    </Grid>
                    {
                        /*
                    
                    <Grid container item xs={12} md={6} lg={3} className={classes.itemRow}>
                        <Grid item xs={2}>
                            <Forum color="secondary" className={classes.icon}></Forum>
                        </Grid>
                        <Grid item xs={10} className={classes.itemTextDiv}>
                            <Typography variant="h2" component="span" align='left' className={classes.itemText}>
                                Better Communication
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} lg={3} className={classes.itemRow}>
                        <Grid item xs={2}>
                            <Assessment color="secondary" className={classes.icon}></Assessment>
                        </Grid>
                        <Grid item xs={10} className={classes.itemTextDiv}>
                            <Typography variant="h2" component="span" align='left' className={classes.itemText}>
                                Track Student Progress
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} lg={3} className={classes.itemRow}>
                        <Grid item xs={2}>
                            <Schedule color="secondary" className={classes.icon}></Schedule>
                        </Grid>
                        <Grid item xs={10} className={classes.itemTextDiv}>
                            <Typography variant="h2" component="span" align='left' className={classes.itemText}>
                                Allow Online Scheduling
                            </Typography>
                        </Grid>
                    </Grid>
                    */
                    }
                </Grid>
            </Container>
        </Container>
    )
}

export default Hero;