import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import ProductImage1 from "../../img/home/home-product1.png";
import ProductImage2 from "../../img/home/home-product2.png";
import ProductImage3 from "../../img/home/home-product3.png";

const useStyles = makeStyles(theme => ({
    bodyContainer: {
        backgroundColor: "white", 
        paddingTop: "3rem",
        paddingBottom: "3rem",
    },
    typeContainer: {
        padding: "2rem"
    }
}));

const Features =() => {
    
    const classes = useStyles();

    return(
        <>
        <Container maxWidth={false} className={classes.bodyContainer}>
            <Container>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h4" component="h1" align="center" className={classes.typeContainer}>Studio Management Software Built for Today</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" component="p" align="center" style={{fontSize: '1.25rem', paddingBottom: "2rem"}}>
                            DanceClarity is built for the modern dance studio and includes everything you need to manage your studio, onboard 
                            and communicate with new students, track students' progress online, and even award points for completed activities.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{display: 'flex', justifyContent: "center", justifyItems: "center", alignItems: 'center'}}>
                        <img src={ProductImage1} alt="dance studio management crm" style={{width: "70%", margin: "auto",}}/>
                    </Grid>
                </Grid>
            </Container>
        </Container>
        <Container maxWidth={false} className={classes.bodyContainer} style={{backgroundColor: "#f2faff"}}>
            <Container>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h5" component="h2">DanceClarity for Studios</Typography>
                        <Typography variant="h6" component="p" style={{fontSize: '1.25rem'}}>
                            Streamline all of your studio processes.
                        </Typography>
                            <ul>
                                <li><Typography variant="body1" component="p" style={{fontSize: '1.25rem'}}>Keep student records organizated online</Typography></li>
                                <li><Typography variant="body1" component="p" style={{fontSize: '1.25rem'}}>View the studio schedule and upcoming lessons all in one place</Typography></li>
                                <li><Typography variant="body1" component="p" style={{fontSize: '1.25rem'}}>Send reminders and emails to students and keep them organized in one place</Typography></li>
                                <li><Typography variant="body1" component="p" style={{fontSize: '1.25rem'}}>Track student progress through each dance offered by your studio and award points for progress</Typography></li>
                            </ul>
                    </Grid>
                    <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: "center", justifyItems: "center", alignItems: 'center'}}>
                        <img src={ProductImage2} alt="streamline dance studio management" style={{width: "70%", margin: "auto", }}/>
                    </Grid>
                </Grid>
            </Container>
        </Container>
        <Container maxWidth={false} className={classes.bodyContainer} >
            <Container>
                <Grid container>
                    <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: "center", justifyItems: "center", alignItems: 'center'}}>
                        <img src={ProductImage3} alt="track dance student progress" style={{width: "70%", margin: "auto"}}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h5" component="h2">DanceClarity for Students</Typography>
                        <Typography variant="h6" component="p" style={{fontSize: '1.25rem'}}>
                            Track progress, even across multiple dance studios.
                        </Typography>
                            <ul>
                                <li><Typography variant="body1" component="p" style={{fontSize: '1.25rem'}}>Instantly view your records and dance progress</Typography></li>
                                <li><Typography variant="body1" component="p" style={{fontSize: '1.25rem'}}>Control which studios have access to your records so that you can keep organized even across multiple studios</Typography></li>
                                <li><Typography variant="body1" component="p" style={{fontSize: '1.25rem'}}>Communicate with instructors to ask questions or leave commments about particular lessons or dance steps</Typography></li>
                                <li><Typography variant="body1" component="p" style={{fontSize: '1.25rem'}}>Schedule lessons online when they fit into your schedule</Typography></li>
                            </ul>
                    </Grid>
                </Grid>
            </Container>
        </Container>
        </>
    )
}

export default Features;